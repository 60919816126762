import { useContext, useState } from 'react';
import {
	Dialog,
	DialogContent,
	Button,
	Typography,
	Box,
	FormControl,
	Select,
	MenuItem,
	InputLabel,
	TextField,
	Backdrop,
	CircularProgress,
} from '@mui/material';
import CustomDatasetsContext from '../../context/CustomDatasetsContext';
import CustomDatasetPreviewTable from './CustomDatasetPreviewTable';
import { updateDataset } from '../../api_helper/api';
import SnackbarAlertContext from '../../context/SnackbarAlertContext';
import { trackEvent } from '../../utils/eventTracking';
import { UserProfileContext } from '../../UserProfileContext';

const EditDatasetDialog = () => {
	const customDatasetsContext = useContext(CustomDatasetsContext);
	const snackbarAlertContext = useContext(SnackbarAlertContext);
	const userProfile = useContext(UserProfileContext)[0];

	const [isLoading, setIsLoading] = useState(false);

	const closeDialogHandler = () => {
		customDatasetsContext.setView(null);
		customDatasetsContext.setCustomDataset(null);
	};

	const datasetNameInputChangeHandler = (event) => {
		customDatasetsContext.setCustomDataset((prevState) => ({
			...prevState,
			...prevState.customDataset,
			name: event.target.value,
		}));
	};

	const joinFieldsChangeHandler = (event) => {
		// Get the names of the selected fields
		const selectedFieldNames = event.target.value.map((field) => field.name);

		// Update the isJoinField property of each field in the custom dataset
		customDatasetsContext.setCustomDataset((prevState) => ({
			...prevState,
			fields: prevState.fields.map((field) => ({
				...field,
				isJoinField: selectedFieldNames.includes(field.name),
			})),
		}));
	};

	const sportSelectChangeHandler = (event) => {
		customDatasetsContext.setCustomDataset((prevState) => ({
			...prevState,
			tags: { ...prevState.tags, sport: event.target.value },
		}));
	};

	const editDatasetClickHandler = async () => {
		// Validate that a dataset name has been entered
		if (!customDatasetsContext.customDataset.name) {
			snackbarAlertContext.setSnackbarAlert({
				msg: 'Please enter a dataset name.',
				severity: 'error',
				autoHide: 5000,
				isSnackbarOpen: true,
			});
			return;
		}

		// Validate that a sport has been selected
		if (!customDatasetsContext.customDataset.tags.sport) {
			snackbarAlertContext.setSnackbarAlert({
				msg: 'Please select a sport.',
				severity: 'error',
				autoHide: 5000,
				isSnackbarOpen: true,
			});
			return;
		}

		// Validate that join fields have been selected
		if (!customDatasetsContext.customDataset.fields.some((field) => field.isJoinField)) {
			snackbarAlertContext.setSnackbarAlert({
				msg: 'Please select at least one join key.',
				severity: 'error',
				autoHide: 5000,
				isSnackbarOpen: true,
			});
			return;
		}

		// Render loading animation
		setIsLoading(true);

		// Edit the dataset in the database
		await updateDataset({
			datasetId: customDatasetsContext.customDataset._id,
			name: customDatasetsContext.customDataset.name,
			tags: {
				sport: customDatasetsContext.customDataset.tags.sport,
				datasetGroup: customDatasetsContext.customDataset.tags.sport,
			},
			fields: customDatasetsContext.customDataset.fields,
		});

		// Remove loading animation
		setIsLoading(false);

		// Close the dialog
		closeDialogHandler();

		// Refresh the list
		customDatasetsContext.setCustomDatasets(undefined);

		trackEvent({
			userDetails: { userId: userProfile._id, email: userProfile.email },
			eventDetails: {
				types: ['KissMetrics', 'AppInsights', 'Segment', 'GA4'],
				eventName: 'WebApp_User Edits custom dataset',
			},
		});
	};

	return (
		Boolean(customDatasetsContext.view === 'edit') && (
			<>
				<Dialog
					open={true}
					onClose={closeDialogHandler}
					fullWidth
					maxWidth="xl"
					PaperProps={{
						style: {
							maxHeight: '50rem',
						},
					}}
				>
					<DialogContent>
						<Typography variant="h2">Edit Private Dataset</Typography>
						<Typography
							variant="footnote"
							sx={{ display: 'block', marginTop: '0.5rem', color: (theme) => theme.palette.danger.main }}
						>
							Edits only affect future uses of this dataset. Existing DataSpaces will not be changed.
						</Typography>

						<Box
							sx={{
								width: '100%',
								flexWrap: 'wrap',
								display: 'flex',
								alignItems: 'end',
								marginTop: '1rem',
								rowGap: '1rem',
								columnGap: '1rem',
							}}
						>
							<FormControl style={{ width: '15rem', flexGrow: 1 }}>
								<TextField
									label="Dataset Name"
									fullWidth
									onChange={datasetNameInputChangeHandler}
									value={customDatasetsContext.customDataset.name}
								/>
							</FormControl>

							<FormControl fullWidth sx={{ width: '20rem' }}>
								<InputLabel>Select Sport</InputLabel>
								<Select
									value={customDatasetsContext.customDataset?.tags?.sport || ''}
									onChange={sportSelectChangeHandler}
									label="Select Sport"
								>
									<MenuItem value="NFL">NFL</MenuItem>
									<MenuItem value="NBA">NBA</MenuItem>
									<MenuItem value="NHL">NHL</MenuItem>
									<MenuItem value="MLB">MLB</MenuItem>
								</Select>
							</FormControl>
						</Box>

						<Box
							sx={{
								width: '100%',
								display: 'flex',
								flexDirection: 'column',
								border: (theme) => `1px solid ${theme.palette.neutral.light}`,
								borderRadius: '0.5rem',
								marginTop: '1rem',
								overflow: 'hidden',
							}}
						>
							<Box sx={{ padding: '1rem' }}>
								<Typography variant="h3">Select a Key Column</Typography>
								<Typography variant="footnote">
									Review your uploaded data and select a Key column to link with other data sets.
								</Typography>
							</Box>

							<CustomDatasetPreviewTable />
						</Box>
					</DialogContent>
					{/* Footer */}
					<Box
						sx={{
							display: 'flex',
							flexWrap: 'wrap',
							justifyContent: 'space-between',
							alignItems: 'center',
							padding: '1.25rem 1.5rem',
							rowGap: '1rem',
							borderTop: (theme) => `1px solid ${theme.palette.neutral.light}`,
						}}
					>
						<FormControl sx={{ width: '100%', maxWidth: '25rem' }}>
							<InputLabel>Select Join Keys</InputLabel>
							<Select
								value={customDatasetsContext.customDataset.fields?.filter((e) => e.isJoinField) || []}
								multiple
								onChange={joinFieldsChangeHandler}
								placeholder="Join Keys"
								displayEmpty
								label="Select Join Keys"
							>
								{customDatasetsContext.customDataset.fields?.map((field) => (
									<MenuItem key={field.name} value={field}>
										{field.displayName}
									</MenuItem>
								))}
							</Select>
						</FormControl>

						<Box>
							<Button variant="outlined" color="neutral" onClick={closeDialogHandler} sx={{ marginRight: '0.75rem' }}>
								Cancel
							</Button>
							<Button variant="contained" color="primary" onClick={editDatasetClickHandler}>
								Edit Dataset
							</Button>
						</Box>
					</Box>
				</Dialog>

				{/* Loading Spinner */}
				<Backdrop open={isLoading} sx={{ width: '100vw', height: '100vh', zIndex: '10000', color: '#FFFFFF' }}>
					<CircularProgress color="inherit" />
				</Backdrop>
			</>
		)
	);
};

export default EditDatasetDialog;
