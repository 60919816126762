import React, { useState, useContext, useEffect, useLayoutEffect } from 'react';
import Backdrop from './Backdrop';
import SideNav from '../side_nav/SideNavigation';
import Header from './Header';
import { appInsights } from '../../utils/ApplicationInsights';

// Placeholder Locations
import Account from '../account/AccountContainer';
import MyData from '../my_data';
import MyDataSpaces from '../my_dataspaces/MyDataSpacesContainer';
import CommunityDataSpaces from '../community_dataSpaces/CommunityDataSpaces';
import LogoutModal from '../side_nav/LogoutModal';
// Styling
import { HeaderContext } from '../../HeaderContext';
import './AppContainer.css';
// App Components
import { getUserProfile } from '../../api_helper/api';
import { SubscriptionManagementDialog } from './SubscriptionManagement/SubscriptionManagementDialog';
//Other
import { UserProfileContext } from '../../UserProfileContext'; // Store User Profile data
import { GlobalAlertContext } from '../../GlobalAlertContext';
import { usePreloadedData } from '../hooks/UsePreloadedData'; // Fetches and stores any data which requires preloading
import MyDataSpacesViewContext from '../my_dataspaces/context/MyDataSpacesViewContext';
import CreateEditDataSpaceContext from '../my_dataspaces/context/CreateEditDataSpaceContext';
import PageContext from '../../context/PageContext';
import { CommunityDataSpacesContextProvider } from '../../context/CommunityDataSpacesContext';
import { useLocation } from 'react-router-dom';
import { CustomDatasetsContextProvider } from '../../context/CustomDatasetsContext';
import { trackEvent } from '../../utils/eventTracking';
// Sidenav Attributes //
const navWidthCollapsed = 64;
const navWidthExpanded = 265;

export default function AppContainer() {
	usePreloadedData(); // Fetch data which requires preloading (stores in context)

	const { search } = useLocation();
	const params = new URLSearchParams(search);

	const pageContext = useContext(PageContext);

	// Sidenav States
	const [expanded, setExpanded] = useState(false);

	// Subscription Management Dialog
	const [eventRequired, setEventRequired] = useState(''); //set to determine which child component should be displayed
	const [open, setOpen] = useState(false);

	// Refresh Key
	/*
  	refreshKey is used to force state reset upon multiple clicks of same nav icon.
  	This allows us to exit the DataSpace view for the DataSpace List view for example.

  	The key is incremented each time a navItem is selected. Components which use
  	refreshKey for key prop will be reset.
 	*/
	const [refreshKey, setRefreshKey] = useState(0);
	const incrementRefreshKey = () => setRefreshKey((prevKey) => prevKey + 1);

	// Header States
	const headerContent = useContext(HeaderContext)[0];

	//User Profile States
	const [userProfile, setUserProfile] = useContext(UserProfileContext);

	// Global Alert
	const [alertOpen, setAlertOpen] = useContext(GlobalAlertContext).globalAlertOpen;
	const alertSettings = useContext(GlobalAlertContext).globalAlertSettings[0];

	const myDataSpacesViewContext = useContext(MyDataSpacesViewContext);
	const createEditDataSpaceContext = useContext(CreateEditDataSpaceContext);

	//UseEffect to check Status of User's Account
	useEffect(() => {
		const setUserContext = async () => {
			// Retrieve the userprofile for the logged in user
			const user = await getUserProfile();

			//Set the state for the user and prepare it to be passed into the child component
			// Set the current user
			setUserProfile(user);

			// Check the status of the user's account
			// Switch statement
			//Do not do anything for active, soft_failure and trialing accounts and grant access
			switch (user.subscriptionState) {
				// Retrieve the subscription details
				// Create a conditional component that directs the user to the billing portal.
				// Check for on_hold, past_due, and unpaid and direct the user to the billing portal.
				case 'on_hold':
				case 'past_due':
				case 'unpaid':
					subscriptionResolution('billingPortal');
					break;
				//Check for canceled, expired, trial_ended, and suspended and present the plans for the user to pick from
				//Pre-populate data into public sign-up page
				case 'canceled':
				case 'trial_ended':
					// case "expired": // Our products are set NOT to expire. This use case is not needed at this time.
					// case "suspended": // The suspended state is only linked to Prepaid products. This use case is not needed at this time.
					subscriptionResolution('sign-up');
					break;
				default:
					break;
			}

			// Clean-up the user email and record the identity of the logged in user in Application Insights
			var validatedId = user.email.replace(/[,;=| ]+/g, '_');
			appInsights.setAuthenticatedUserContext(validatedId);
			appInsights.trackPageView();
		};

		setUserContext();
	}, []);

	useEffect(() => {
		if (userProfile.subscriptionState === 'active') {
			// If the user's subscription state is updated to active, we close the SubscriptionManagementDialog modal.
			setOpen(false);
		}
	}, [userProfile]);

	/*
	 * Set the subscriptionResolution state to billingPortal or sign-up and trigger the modal to open
	 * @param - action required to correct the billing issue
	 */
	const subscriptionResolution = (action) => {
		handleOpen();
		setEventRequired(action);
	};

	//Modal window Open
	const handleOpen = () => {
		setOpen(true);
	};

	// Sidenav Props
	const onToggle = (expanded) => {
		setExpanded(expanded);
	};
	const onSelect = (selected) => {
		if (selected === 'logout') {
			myDataSpacesViewContext.setIsLogoutModalOpen(true);
			return;
		}
		if (pageContext.page === 'mydataspaces') {
			createEditDataSpaceContext.resetNewDataSpace();
			myDataSpacesViewContext.changeViewHandler('home');
		}
		if (selected === 'MyData') {
			trackEvent({
				userDetails: { userId: userProfile._id, email: userProfile.email },
				eventDetails: {
					types: ['KissMetrics', 'AppInsights', 'Segment', 'GA4'],
					eventName: 'WebApp_User Visits My Data page',
				},
			});
		}

		pageContext.setPage(selected);
		incrementRefreshKey();
	};

	function changeView(view) {
		pageContext.setPage(view);
	}

	// Displays content based on sidenav selection
	let displayComponent = () => {
		switch (pageContext.page) {
			case 'account':
				return <Account key={refreshKey} />;
			case 'mydataspaces':
				return <MyDataSpaces key={refreshKey} />;
			case 'CommunityDataSpaces':
				return (
					<CommunityDataSpacesContextProvider>
						<CommunityDataSpaces />
					</CommunityDataSpacesContextProvider>
				);
			default:
				return (
					<CustomDatasetsContextProvider>
						<MyData />
					</CustomDatasetsContextProvider>
				);
		}
	};

	useEffect(() => {
		if (params.get('route')?.startsWith('communityDataSpacePreview')) {
			pageContext.setPage('CommunityDataSpaces');
		}
	});

	return (
		<div className="app-container">
			<Backdrop open={expanded} />

			{headerContent && <Header breadcrumb={headerContent} />}

			<SideNav
				onToggle={onToggle}
				onSelect={onSelect}
				setExpanded={setExpanded}
				expanded={expanded}
				navWidthExpanded={navWidthExpanded}
				navWidthCollapsed={navWidthCollapsed}
			/>
			<div className="displayed-component">{displayComponent()}</div>

			<LogoutModal />

			{/*Display dialog alert if the user doesn't have a plan or the users*/}
			{SubscriptionManagementDialog(open, eventRequired, userProfile, () => {
				setOpen(false);
			})}
		</div>
	);
}
