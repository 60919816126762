import { CircularProgress, Grid, makeStyles } from '@material-ui/core';
import DataSpace from './DataSpace';
import { useContext, useEffect } from 'react';
import { getDataSpaces } from '../../api_helper/api';
import CommunityDataSpacesContext from '../../context/CommunityDataSpacesContext';
import { UserProfileContext } from '../../UserProfileContext';

const useStyles = makeStyles((theme) => ({
	dataSpacesList: {
		margin: '0',
		padding: '1rem',
		backgroundColor: '#FFFFFF',
		borderRadius: '0.5rem',
		overflowY: 'auto',
		listStyle: 'none',
		'& > li': {
			width: '100%',
		},
		'& > li:last-child > button': {
			border: 'none',
		},
	},
}));

const DataSpacesList = (props) => {
	const classes = useStyles();

	const communityDataSpacesContext = useContext(CommunityDataSpacesContext);
	const userProfile = useContext(UserProfileContext)[0];

	console.log('communityDataSpacesContext.dataSpaces: ', communityDataSpacesContext.dataSpaces);

	const dataSpaceComponents = communityDataSpacesContext.dataSpaces
		?.filter((dataSpace) => {
			// Filter DataSpaces based on search filter, sports filter, datasets filter, and fantasy platform filter.
			const dataSpacesMatchesSearchQuery = dataSpace.publicName
				.toLowerCase()
				.includes(communityDataSpacesContext.searchQuery.toLowerCase());

			const dataSpaceMatchesSportsFilter = communityDataSpacesContext.filters.sports.length
				? dataSpace.datasets.some((dataset) => communityDataSpacesContext.filters.sports.includes(dataset.tags.sport))
				: true;

			const dataSpaceMatchesDatasetsFilter = communityDataSpacesContext.filters.datasets.length
				? dataSpace.datasets.some((dataset) =>
						communityDataSpacesContext.filters.datasets.includes(dataset.displayName),
				  )
				: true;

			const dataSpaceMatchesFantasyApisFilter = communityDataSpacesContext.filters.fantasyApis.length
				? dataSpace.datasets.some((dataset) =>
						communityDataSpacesContext.filters.fantasyApis.includes(dataset.tags.api),
				  )
				: true;

			return (
				dataSpacesMatchesSearchQuery &&
				dataSpaceMatchesSportsFilter &&
				dataSpaceMatchesDatasetsFilter &&
				dataSpaceMatchesFantasyApisFilter
			);
		})
		.sort((a, b) => {
			// Sort DataSpaces based on either name or published date, based on which sort value is selected.
			switch (communityDataSpacesContext.filters.sortValue) {
				case 'recent':
					return new Date(b.publishedDate) - new Date(a.publishedDate);
				case 'alphanumeric':
				default:
					return a.publicName.toLowerCase().localeCompare(b.publicName.toLowerCase());
			}
		})
		.map((dataSpace) => (
			<Grid item container xs={12} sm={6} lg={4} key={dataSpace._id}>
				<DataSpace dataSpace={dataSpace} />
			</Grid>
		));

	// Get Community DataSpaces
	useEffect(() => {
		const getCommunityDataSpaces = async () => {
			const dataSpacesRes = await getDataSpaces();
			communityDataSpacesContext.setDataSpaces(dataSpacesRes);
		};

		getCommunityDataSpaces();
	}, []);

	return (
		<Grid
			container
			spacing={3}
			style={{
				width: '100%',
				backgroundColor: 'white',
				margin: '0',
				padding: '0 1.5rem 1.5rem 0',
			}}
		>
			{communityDataSpacesContext.dataSpaces ? (
				dataSpaceComponents.length ? (
					dataSpaceComponents
				) : (
					<p style={{ margin: 'auto', color: 'grey' }}>No DataSpaces Found</p>
				)
			) : (
				<div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
					<CircularProgress />
				</div>
			)}
		</Grid>
	);
};

export default DataSpacesList;
