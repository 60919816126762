import { useContext, useEffect, useState } from 'react';
import { makeStyles, Card, Typography, Grid } from '@material-ui/core';
import NBALogoPNG from '../../../assets/img/NBALogoSquare.png';
import MLBLogoPNG from '../../../assets/img/MLBLogoSquare.png';
import NFLLogoPNG from '../../../assets/img/NFLLogoSquare.png';
import NHLLogoPNG from '../../../assets/img/NHLLogoSquare.png';
import YahooFantasyLogoPNG from '../../../assets/img/YahooFantasyLogo.png';
import FantraxLogoPNG from '../../../assets/img/FantraxLogo.png';
import CalculatorLogoPNG from '../../../assets/img/CalculatorLogo.png';
import SnackbarAlertContext from '../../../context/SnackbarAlertContext';

const useStyles = makeStyles((theme) => ({
	datasetWrapper: {
		width: '100%',
		padding: '0.5rem 0',
		display: 'flex',
		flexDirection: 'column',
		backgroundColor: 'transparent',
		border: 'none',
		borderBottom: '1px solid black',
		borderRadius: '0',
		boxShadow: 'none',
		textAlign: 'left',
	},
	datasetLogo: {
		height: '6rem',
		marginRight: '0.8rem',
		borderRadius: '0.5rem',
	},
	datasetInfoWrapper: {
		width: '100%',
		display: 'flex',
		marginBottom: '0.5rem',
	},
	datasetInfoHeader: {
		marginBottom: '0rem',
		textTransform: 'uppercase',
	},
	datasetFieldsWrapper: {
		height: '100%',
		overflow: 'hidden',
	},
	datasetField: {
		width: '100%',
		height: 'fit-content',
		padding: '0.3rem',
		backgroundColor: '#C4C4C4',
		borderRadius: '0.2rem',
		transition: '0.3s',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		'&:hover': {
			backgroundColor: '#C4C4C4',
			filter: 'brightness(0.75)',
			cursor: 'pointer',
		},
		'& > span': {
			width: '100%',
			display: 'inline',
			color: '#000000',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
		},
	},
}));

// Dataset list item showing dataset name, image showing dataset sport, and its fields.
const Dataset = (props) => {
	const classes = useStyles();

	const snackbarAlertContext = useContext(SnackbarAlertContext);

	const fieldClickHandler = (field) => {
		const name =
			field.dataSourceName === 'Calculated'
				? `#${field.displayName}(calc)`
				: `#${field.displayName}(${field.name.substr(-3)})`;

		props.setExpression((prevState) => ({
			...prevState,
			selectionEnd: prevState.selectionEnd + name.length,
			value: prevState.value.slice(0, prevState.selectionEnd) + name + prevState.value.slice(prevState.selectionEnd),
		}));
	};

	const datasetFieldComponents = !props.dataset.fields.length ? (
		<Grid container item>
			<Grid item style={{ color: '#777777' }}>
				No columns
			</Grid>
		</Grid>
	) : (
		props.dataset.fields.reduce((accumulator, field) => {
			!field.isExcluded &&
				props.numericalColumns.some((column) => column.field === (field.name || field.field)) &&
				accumulator.push(
					<Grid
						container
						item
						key={field.name}
						xs={12}
						sm={4}
						md={3}
						onClick={() => {
							fieldClickHandler(field);
						}}
					>
						<div className={classes.datasetField}>{field.displayName || field.name || field.header}</div>
					</Grid>,
				);
			return accumulator;
		}, [])
	);

	const getDatasetLogoSource = () => {
		switch (props.dataset.tags.datasetGroup) {
			case 'NBA':
				return NBALogoPNG;
			case 'MLB':
				return MLBLogoPNG;
			case 'NFL':
				return NFLLogoPNG;
			case 'NHL':
				return NHLLogoPNG;
			case 'Calculated Columns':
				return CalculatorLogoPNG;
			case 'YahooNBA':
			case 'YahooMLB':
			case 'YahooNHL':
			case 'YahooNFL':
				return YahooFantasyLogoPNG;
			case 'FantraxNBA':
			case 'FantraxMLB':
			case 'FantraxNHL':
			case 'FantraxNFL':
				return FantraxLogoPNG;
		}
	};

	return (
		<li>
			<button className={classes.datasetWrapper}>
				<div className={classes.datasetInfoWrapper}>
					<img src={getDatasetLogoSource()} alt="Dataset logo." className={classes.datasetLogo} />

					<div style={{ display: 'flex', flexDirection: 'column' }}>
						<Typography className={classes.datasetInfoHeader}>
							{props.dataset.displayName || props.dataset.name}
							{props.dataset.tags.timeFrame && ' ' + props.dataset.tags.timeFrame}
							{props.dataset._id && ` (${props.dataset._id.substring(21)})`}
						</Typography>
						<Typography style={{ fontSize: '0.8rem', color: '#777777' }}>
							Copy and paste field names from the list below to add into your expression (Click to add to expression).
						</Typography>
					</div>
				</div>

				<Grid container spacing={0.5} className={classes.datasetFieldsWrapper}>
					{datasetFieldComponents}
				</Grid>
			</button>
		</li>
	);
};

export default Dataset;
