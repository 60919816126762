import { Box, SvgIcon, Typography } from '@mui/material';
import { ReactComponent as UploadDataIcon } from '../../assets/icons/upload-data.svg';
import CreateDatasetButton from './CreateDatasetButton';

const NoCustomDatasets = () => {
	return (
		<Box
			sx={{
				width: '100%',
				height: '100%',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
			}}
		>
			<Box
				sx={{
					width: '100%',
					maxWidth: '32rem',
					marginTop: '4rem',
					padding: '0 0.5rem',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
				}}
			>
				{/* Upload Data Graphic */}
				<SvgIcon style={{ width: '75%', height: 'auto' }}>
					<UploadDataIcon />
				</SvgIcon>

				<Typography variant="h2" sx={{ marginTop: '1rem' }}>
					Upload Your Own Data
				</Typography>

				<Typography variant="subtitle1" sx={{ marginTop: '1rem' }}>
					Easily import your data set to start building customizable views, analyze player stats, and get automatic
					updates
				</Typography>

				<CreateDatasetButton />

				<Typography variant="footnote" textAlign="center" sx={{ marginTop: '1rem' }}>
					Please note, all files must be uploaded in a .csv format and contain a header
				</Typography>
			</Box>
		</Box>
	);
};

export default NoCustomDatasets;
